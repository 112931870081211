import logo from './logo.svg';
import './App.css';
import Routings from './Components/Routings/Routings';

function App() {
  return (
    <div className="App">
     <Routings/>
    </div>
  );
}

export default App;
